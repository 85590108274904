.wrapper {
  width: 100vw;
  min-height: 75vh;
  position: relative;
  background: white;
  padding: 2.5rem 0rem;
}

.container {
  width: 100%;
  min-height: 75vh;
  display: flex;
  padding: 2rem 0rem;
  padding-top: 4rem;
  padding-bottom: 0rem;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 3rem 2rem;
}

.title {
  width: calc(40% - 1rem);
  background: white;
  display: flex;
  align-items: center;
  padding: 4rem 2rem;
  padding-left: 4rem;
  background: black;
  color: white;
}

.form {
  width: calc(60% - 1rem);
  background: white;
  transition: all 0.25s ease;
}

.title > h1 {
  font-size: 8rem;
  line-height: 9rem;
  border-bottom: 10px solid #E43424;
}

.formTitle {
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
  color: black;
  padding: 2rem;
}

.formContent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding-bottom: 4rem;
  transition: all 0.25s ease-in-out;
}

.formButton {
  text-decoration: none;
  font-size: 2rem;
  padding: 1rem 2rem;
  background: white;
  border: none;
  border-bottom: 2px solid #E43424;
  transition: all 0.4s ease-in-out;
}

.formButton:hover {
  background: #E43424;
  color: white;
  transform: sclae(1.05);
}

.content {
}



@media (max-width: 940px) {
  .container {
    gap: 0rem;
  }
  .title {
    width: 100%;
    padding: 2rem 3rem;
    padding-right: 5rem;
  }
  .title > h1 {
    text-align: left;
    font-size: 5rem;
    line-height: 6rem;
    border-bottom: 10px solid #E43424;
  }
  .form {
    width: 100%;
  }
  .formTitle {
    line-height: 3.5rem;
  }
  .formContent {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding-bottom: 4rem;
    transition: all 0.25s ease-in-out;
    padding: 3rem;
    padding-top: 1rem;
  }
  .formButton {
    text-decoration: none;
    font-size: 2rem;
    padding: 1rem 0rem;
    background: white;
    border: none;
    width: 100%;
    border-bottom: 2px solid #E43424;
    transition: all 0.4s ease-in-out;
  }
}
