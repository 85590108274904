.container {
  min-height: 10vh;
  width: 100vw;
  background: rgb(40,40,40);
}

.wrapper {
  width: 100%;
  min-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
}

.wrapper > p{
  color: #FFFFFA;
  background: rgb(40,40,40);
  font-size: 2em;
  padding-left: 1em;
  padding-right: 1em;
  text-align: center;
}


