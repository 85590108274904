a {
  text-decoration: none;
}

.header {
  position: sticky;
  top: 0;
  height: 76px;
  width: 100vw;
  background-color: white;
  color: #ff0000;
  padding: 0.5rem 1.5rem;
  display: flex;
  justify-content: space-between;
  z-index: 1000;
  border-bottom: 3px solid #ff0000;
}

.genius {
  height: 100%;
}

.genius > img {
  height: 100%;
}

.menu {
  display: none;
}

nav {
  display: flex;
  height: 100%;
  transition: all 0.5s ease;
}

.opened {
  display: block;
  transition: all 0.5s ease;
}

.closed {
  display: none;
  transition: all 0.5s ease;
}

nav > ul {
  height: 100%;
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

nav > ul > li {
  max-height: 80%;
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;
  align-items: center;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;
}

nav > ul > li:hover {
  border-bottom: 3px solid #ff0000;
}

nav > ul > li > a {
  color: black;
}

.side-menu:checked ~ .hamb .hamb-line {
    transform: rotate(-45deg);
}
.side-menu:checked ~ .hamb .hamb-line::after {
    transform: rotate(90deg);
    top:0;
}

@media screen and (max-width: 940px) {
  nav {
    display: none;
    height: auto;
    background-color: white;
    transform: translateX(-1.5rem);
    width: 100vw;
    padding: 1em;
    border-bottom: 4px solid #ff0000;
  }
  .header {
    display: block;
    min-height: 60px;
  }
  nav > ul > li {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  nav > ul {
    display: block;
  }
  .genius {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .menu {
    display: block;
  }

  .hamb{
    cursor: pointer;
    padding: 40px 20px;
   }/* Style label tag */

  .hamb-line {
      background: #ff0000;
      display: block;
      height: 3px;
      border-radius: 20px;
      position: relative;
      width: 24px;
      transition: all .4s ease-out;

  } /* Style span tag */
  
  .hamb-line::after{
      background: #ff0000;
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      transition: all .4s ease-out;
      width: 100%;
  }
  .hamb-line::after{
      top: 10px;
  }
  
  .side-menu {
      display: none;
  }
}


@media screen and (min-width: 941px) {
  nav {
    display: flex !important;
  }
}
