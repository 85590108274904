.container {
  width: 100vw;
  height: 80vh;
  padding: 2rem;
}

.title {
  font-size: 4rem;
  color: black;
}

.subtitle {
  font-size: 2rem;
  color: black;
}

.slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
