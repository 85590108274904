.container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 1rem;
  justify-content: center;
}

.column {
  width: calc((100% - 2rem) / 3);
}

@media (max-width: 880px) {
  .column {
    width: calc((100% - 2rem) / 2);
  }
}

@media (max-width: 600px) {
  .column {
    width: 100%;
  }
  .container {
    gap: 0rem;
  }
}


