.wrapper {
  display: flex;
  width: 100vw;
  background: black;
  gap: 2rem;
  padding: 2rem;
  flex-wrap: wrap;
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.item {
  width: calc(50% - 1rem);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.title {
  font-size: 2.75rem;
  text-align: center;
  font-weight: bold;
}

.text {
  color: white;
  font-size: 2rem;
  font-weight: bold;
  padding-left: 1rem;
  padding-right: 1rem;
}

.highlight {
  color: #ff2020;
}

@media (max-width: 860px) {
  .item {
    width: 100%;
  }
  .wrapper {
    flex-direction: column-reverse;
  }
}
