.product {
  height: 50vh;
  display: flex !important;
  align-items: center;
  flex-direction: column;
}

.product-slider > p {
  text-align: center;
  font-size: 4rem;
  color: #0A0908;
  font-weight: 800;
}

.product-slider {
  padding: 2rem 2rem;
  width: 100vw;
}

.product > img {
  height: 58%;
  object-fit: contain;
}

.product-title {
  color: #2C4DE4;
  font-size: 2.8rem;
  font-weight: 600;
}

.product-price {
  color: #0A0908;
  font-size: 1.8rem;
  font-weight: 600;
  text-decoration: line-through;
}

.price-discount {
  color: #E43424;
  font-size: 2rem;
  font-weight: 700;
}

.build-now {
  background-color: #2C4DE4;
  border: none;
  color: #FFFFFA;
  padding: 1em 2em;
  font-size: 1rem;
}
