.container {
  width: 100vw;
  height: 80vh;
  padding: 4rem 12rem;
  display:flex;
  justify-content: center;
  align-items: center;
}


.video {
  height: 100%;
  width: 100%;
}
