:root {
  --color-text: black;
  --color-bg: black;
  --color-bg-accent: black;
  --size: clamp(10rem, 1rem + 40vmin, 30rem);
  --gap: calc(var(--size) / 14);
  --duration: 60s;
  --scroll-start: 0;
  --scroll-end: calc(-100% - var(--gap));
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-text: black;
    --color-bg: black;
    --color-bg-accent: black;
  }
}

* {
  box-sizing: border-box;
}

.title {
  padding: 0rem 3rem;
  padding-top:3rem;
  padding-bottom: 3rem;
  font-size: 4rem;
  font-weight: 700;
  color: white;
  text-align: center;
  line-height: 4rem;
}

.marquee {
  display: flex;
  overflow: hidden;
  user-select: none;
  background-color: var(--grayOne);
  gap: var(--gap);
  mask-image: linear-gradient(
    var(--mask-direction, to right),
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 20%,
    hsl(0 0% 0% / 1) 80%,
    hsl(0 0% 0% / 0)
  );
}

.marquee__group {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
  animation: scroll-x var(--duration) linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .marquee__group {
    animation-play-state: paused;
  }
}

.marquee--vertical {
  --mask-direction: to bottom;
}

.marquee--vertical,
.marquee--vertical .marquee__group {
  flex-direction: column;
}

.marquee--vertical .marquee__group {
  animation-name: scroll-y;
}

.marquee__reverse .marquee__group {
  animation-direction: reverse;
  animation-delay: -3s;
}

@keyframes scroll-x {
  from {
    transform: translateX(var(--scroll-start));
  }
  to {
    transform: translateX(var(--scroll-end));
  }
}

@keyframes scroll-y {
  from {
    transform: translateY(var(--scroll-start));
  }
  to {
    transform: translateY(var(--scroll-end));
  }
}

/* Element styles */
.marquee .marquee__item {
  display: grid;
  place-items: center;
  fill: var(--color-text);
  background: var(--color-bg-accent);
  padding: calc(var(--size) / 10);
  border-radius: 0.5rem;
}

.marquee__vertical .marquee__item {
  width: calc(var(--size) / 1.5);
  padding: calc(var(--size) / 6);
}

.marquee__item img {
  height: 150px;
}

/* Parent wrapper */
.wrapper {
  padding: 1rem 0rem;
  background-color: var(--grayOne);
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  margin: auto;
  max-width: 100vw;
}

.wrapper__vertical {
  flex-direction: row;
  height: 100vh;
}

/* Toggle direction button */
.toggle {
  --size: 3rem;
  position: relative;
  position: fixed;
  top: 1rem;
  left: 1rem;
  width: var(--size);
  height: var(--size);
  font: inherit;
  text-align: center;
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 50%;
  color: inherit;
  background-color: var(--color-bg-accent);
  z-index: 1;
}

.toggle:focus-visible {
  box-shadow: 0 0 0 2px var(--color-text);
}

.toggle span {
  position: absolute;
  display: inline-block;
  top: 50%;
  left: calc(100% + 0.4em);
  width: fit-content;
  white-space: nowrap;
  transform: translateY(-50%);
  animation: fade 400ms 4s ease-out forwards;
  user-select: none;
}

.toggle .marquee__item {
  --size: 1.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  width: var(--size);
  height: var(--size);
  fill: currentcolor;
  transform: translate(-50%, -50%);
  transition: transform 300ms cubic-bezier(0.25, 1, 0.5, 1);
}

.toggle__vertical .marquee__item {
  transform: translate(-50%, -50%) rotate(-90deg);
}

@keyframes fade {
  to {
    opacity: 0;
    visibility: hidden;
  }
}

