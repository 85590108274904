
.wrapper {
  min-height: 70vh;
  width: 100vw;
  max-width: 100vw;
  overflow-x: hidden;
}

.container {
  min-height: 70vh;
  width: 100%;
  padding: 6rem 0rem;
  display: flex;
  background: black;
  gap: 2rem;
}

.leftSide {
  width: 40%;
  background: black;
  padding: 2rem;
  color: white;
  display: flex;
  align-items: center;
}

.leftTitle {
  font-size: 8rem;
  line-height: 9rem;
  border-bottom: solid 10px red;
  font-weight: 600;
}

.rightSide {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  color: white;
}

.rightTitle {
  width: 100%;
  font-size: 2.5vw;
  line-height: 3vw;
  text-align: center;
  font-weight: 500;
}

.rightContent {
  width: 100%;
  display: flex;
  gap: 1rem;
  padding-top: 1.5rem;
  justify-content: center;
}

.contentButton {
  width: 25%;
  display: flex;
  justify-content: center;
  font-size: 2rem;
}

@media (max-width: 940px) {
  .leftSide {
    width: 100%;
    padding: 2rem 3rem;
    padding-right: 5rem;
  }
  .container {
    flex-wrap: wrap;
  }
  .leftTitle {
    font-size: 5rem;
    line-height: 6rem;
  }
  .rightSide {
    width: 100%;
    padding: 1rem 2rem;
    padding-top: 0rem;
  }
  .rightTitle {
    font-size: 2rem;
    line-height: 2.5rem;
  }
  .rightContent {
    flex-wrap: wrap;
  }
  .contentButton {
    width: calc(50% - .5rem);
    font-size: 1.25rem;
  }
  .container {
    padding-bottom: 2rem;
    padding-top: 4.5rem;
  }
}
