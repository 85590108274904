.container {
  min-height: 80vh;
  width: 100vw;
  padding: 2rem 0rem;
  background: black;
}

.title {
  padding: 0rem 2rem;
  padding-top:3rem;
  padding-bottom: 3rem;
  font-size: 4rem;
  font-weight: 700;
  color: white;
  text-align: center;
  line-height: 4rem;
}

.families {
  max-height: 80vh;
  overflow-y: scroll;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 1rem;
  background: #000;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: .25rem 5rem;
}

.family {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  padding: 0rem .5rem;
  color: #999;
  transition: all 0.3s ease-in-out;
}
.family:hover {
  color: #bbb;
  scale: 1.1;
}

.indproduct {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  padding: .5rem .5rem;
  color: white;
  transition: all 0.3s ease-in-out;
  border: 1px solid rgba(255,0,0,.75);
  border-radius: 5px;
}
.indproduct:hover {
  color: white;
  scale: 1.05;
}

.catTitle {
  font-size: 2.5rem;
  font-weight: 500;
  color: white;
  transition: all 0.3s ease-in-out;
  width: 100%;
  text-align: center;
}


.siblings {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: .5rem 4rem;
  background: #000;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: .25rem 5rem;
  padding-bottom: .75rem;
}

.sibling {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  padding: 0rem .5rem;
  color: #999;
  transition: all 0.3s ease-in-out;
}
.sibling:hover {
  color: #bbb;
  scale: 1.1;
}

.lines {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: .5rem 4rem;
  background: #000;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: .25rem 5rem;
}

.line {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  padding: 0rem .5rem;
  color: #999;
  transition: all 0.3s ease-in-out;
}
.line:hover {
  color: #bbb;
  scale: 1.1;
}

.product{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  padding: 5rem 4rem;
  padding-top: 5rem;
  width: 100%;
}

.productImgholder {
  width: 42%;
  min-width: 300px;
}

.productImgholder img {
  width: 100%;
  height: auto;
}

.productInfo {
  width: 58%;
  min-width: 450px;
  padding: 1rem 2rem;
}

.productTitle {
  font-size: 2.5rem;
  line-height: 0rem;
  font-weight: 700;
  color: #333;
  padding: 1rem 0rem;
  padding-bottom: 1.5rem;
}

.productDes {
  font-size: 1.5rem;
  line-height: 0rem;
  font-weight: 500;
  color: #aaa;
  padding: 1.25rem 0rem;
}

.productBreak {
  width: 100%;
  height: 1px;
  background: #777;
  margin: .25rem 0rem;
}

.productCall {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: .25rem;
}

.productPrice {
  font-size: 1.5rem;
  line-height: 0rem;
  font-weight: 700;
  color: var(--mainColor);
  padding: 1rem 0rem;
}

.productBtn {
  text-decoration: none;
  border: 1px solid var(--mainColor);
  font-size: 1rem;
  line-height: 0rem;
  font-weight: 600;
  color: var(--mainColor);
  background: none;
  padding: 1rem .25rem;
  margin-left: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.productBtn:hover {
  background: #eeeeff;
}


.lineholder {
  position: absolute;
  left: 2rem;
  bottom: 0rem;
  color: #fff;
  border-bottom: 1px solid var(--mainColor);
  padding-right: 7rem;
}
.familyholder {
  position: absolute;
  right: 1.75rem;
  bottom: 0rem;
  color: #fff;
  border-bottom: 1px solid var(--mainColor);
  padding-left: 7rem;
}
.modelholder {
  position: absolute;
  left: 2.5rem;
  bottom: .5rem;
  color: #fff;
  border-bottom: 1px solid var(--mainColor);
  padding-right: 7rem;
}

.mobileTitle {
  display: none;
}

@media screen and (max-width: 940px) {
  .title {
    font-size: 3rem;
    line-height: 3rem;
    padding-bottom: 0rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .lines {
    border-bottom: 1px solid var(--mainColor);
  }
  .families {
    border-bottom: 1px solid var(--mainColor);
  }
  .siblings {
  }
  .lineholder {
    border-bottom: none;
    left: 1rem;
  }
  .familyholder {
    border-bottom: none;
    right: 1rem;
  }
  .modelholder {
    border-bottom: none;
    left: 1rem;
  }
  .mobileTitle {
    display: block;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 500;
    color: var(--grayEleven);
    text-align: center;
    padding: 1rem 2rem;
    padding-bottom: 3rem;
  }
  .sibling {
    border-bottom: none !important;
  }
  .line {
    border-bottom: none !important;
  }
  .family {
    border-bottom: none !important;
  }
  .product{
    flex-direction: column;
  }
  .productInfo {
    width: 100%;
    padding: 1rem 0rem;
    min-width: 250px;
  }
}
