.container {
  width: 100vw;
  min-height: 80vh;
  background: #fffffa;
}

.wrapper {
  width: 100%;
  height: 100%;
  padding: 4rem 6rem;
  display: flex;
  gap: 4rem;
}

.text {
  width: calc(100vw - 14rem - 80vh + 6rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image {
  height: calc(80vh - 8rem);
}
