.container {
  width: 100vw;
  padding: 2rem 2rem;
  padding-top: 1rem;
  padding-bottom: 5rem;
  background: black;
}

.mailPhoneWhatsapp {
  display: flex;
  height: 10rem;
  gap: 1.5rem;
  align-items: center;
  margin: 0 1rem;
  width: 100%;
  justify-content: center;
}

.button {
  background: transparent;
  padding: 1rem 1.5rem;
  border-radius: .25rem;
  color: rgba(255,0,0,.7);
  border: 1px solid rgba(255,0,0,.7);
  display: flex;
  aspect-ratio: 1/1;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;
}

.button:hover {
  background: rgba(255,0,0,.2);
}

.icon {
  font-size: 2.5rem;
}


.title {
  padding: 0rem 4rem;
  padding-top: 4rem;
  width: 100%;
  text-align: center;
  font-size: 4rem;
  font-weight: 700;
  line-height: 5rem;
  padding-bottom: 3rem;
  color: white;
}

.subtitle {
  width: 100%;
  text-align: center;
  font-size: 2rem;
  font-weight: 800;
  line-height: 2.5rem;
  color: var(--grayTen);
  padding: .5rem 0rem;
  border-bottom: 2px solid var(--mainColor);
  display: flex;
  padding: 1.5rem 1rem;
  gap: 1rem;
  justify-content: center;
}

.description {
  text-align: center;
  font-size: 1.25rem;
  padding: .5rem 1rem;
  color: white;
}

.column {
  width: 100%;
  padding: 2rem;
}

.subtitle > p:nth-child(1) {
  font-size: 5rem;
  font-weight: 600;
  color: rgba(255,0,0,0.7);
}

.subtitle > p:nth-child(2) {
  font-size: 2.5rem;
  color: white;
}

@media screen and (max-width: 940px) {
  .title {
    font-size: 3rem;
    line-height: 4rem;
    padding-bottom: 0rem;
  }
  .container {
    padding: 2rem 2rem;
  }
  .column {
    padding: 2rem 0rem;
    padding-bottom: 0rem;
  }
  .subtitle {
    padding: 0rem 1rem;
    padding-bottom: 1.5rem;
  }
  .description {
    padding-top: 1rem;
  }
}
