.container {
  width: 100vw;
  padding: 2rem 4rem;
  padding-top: 1rem;
  padding-bottom: 5rem;
  background: white;
}

.title {
  width: 100%;
  text-align: center;
  font-size: 4rem;
  font-weight: 700;
  line-height: 5rem;
  padding-bottom: 3rem;
  color: #111;
}

.subtitle {
  width: 100%;
  text-align: center;
  font-size: 2rem;
  font-weight: 800;
  line-height: 2.5rem;
  color: #555;
  padding: .5rem 0rem;
  border-bottom: 2px solid #E43424;
}

.description {
  text-align: center;
  font-size: 1.25rem;
  padding: .5rem 1rem;
}

.column {
  width: 100%;
  padding: 2rem;
}

@media screen and (max-width: 940px) {
  .title {
    font-size: 3rem;
    line-height: 4rem;
    padding-bottom: 0rem;
  }
  .container {
    padding: 2rem 2rem;
  }
  .column {
    padding: 2rem 0rem;
    padding-bottom: 0rem;
  }
  .subtitle {
    padding: 0rem 1rem;
    padding-bottom: .5rem;
  }
  .description {
    padding-top: 1rem;
  }
}
