:root {
  --white: #fff;
  --black: #000;
  --darkRed: rgb(255, 69, 58);
  --lightRed: rgb(255, 59, 48);
  --darkBlue: rgb(10, 132, 255);
  --lightBlue: rgb(0, 122, 255);
  --darkGreen: rgb(50, 215, 75);
  --lightGreen: rgb(40, 205, 65);
  --darkYellow: rgb(255, 214, 10);
  --lightYellow: rgb(255, 204, 0);
  --darkOrange: rgb(255, 159, 10);
  --lightOrange: rgb(255, 149, 0);
  --darkMint: rgb(102, 212, 207);
  --lightMint: rgb(0,199, 190);
  --darkTeal: rgb(106, 196, 220);
  --lightTeal: rgb(89, 173, 196);
  --darkCyan: rgb(90, 200, 245);
  --lightCyan: rgb(85, 190, 240);
  --darkIndigo: rgb(94, 92, 230);
  --lightIndigo: rgb(88, 86, 214);
  --darkPurple: rgb(191, 90, 242);
  --lightPurple: rgb(175, 82, 222);
  --darkPink: rgb(255, 55, 95);
  --lightPink: rgb(255, 45, 85);
  --darkGray: rgb(152, 152, 157);
  --lightGray: rgb(142, 142, 147);
  --darkBrown: rgb(172, 142, 104);
  --lightBrown: rgb(162, 132, 94);
  --grayOne: rgb(28,28,30);
  --grayTwo: rgb(44,44,46);
  --grayThree: rgb(58, 58, 60);
  --grayFour: rgb(72, 72, 74);
  --grayFive: rgb(99,99,102);
  --graySix: rgb(142, 142, 147);
  --graySeven: rgb(174, 174, 178);
  --grayEight: rgb(199, 199, 204);
  --grayNine: rgb(209, 209, 214);
  --grayTen: rgb(229, 229, 234);
  --grayEleven: rgb(242, 242, 247);
  --mainColor: rgb(255,0,0);
}

body {
  background: black;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: clip;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Theming */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Saira+Condensed:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root{
    --white: #f9f9f9;
    --black: #36383F;
    --grey: #85888C;
}

/* Reset */
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    background-color: var(--white);
    font-family: 'Saira Condensed', sans-serif;
}

a{
    text-decoration: none;
}

ul{
    list-style: none;
}

html {
  background: #000;
}
