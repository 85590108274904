.container {
  height: 80vh;
  background: black;
  width: 100vw;
  padding: 2rem 2rem;
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0,0,0,0) 48%, rgba(0,0,0,0) 52%, rgba(0, 0, 0, 1) 100%);
  z-index: 1;
}
.bgimg {
  position: absolute;
  opacity: 0.15;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.holder {
  width: 100%;
  background: pink;
  color: white;
}

.header {
  z-index: 2;
  position: relative;
  padding-top: 3rem;
  font-size: 4rem;
  font-weight: 600;
  text-align: center;
  line-height: 4rem;
  padding: 1rem 0rem;
  padding-top: 3rem;
  clip-path: inset(1px);
  color: white;
  background: -webkit-linear-gradient(55deg, #E43424, #ffce00);
  -webkit-background-clip: text;
  background-size: 150% 200%;
  -webkit-text-fill-color: transparent;
  animation: GradientText 3s ease infinite;
}

.navcontainer {
  background: transparent;
}
.navitem {
  font-size: 4rem;
  font-weight: 700;
  text-align: center;
  padding: 0rem 0rem;
  clip-path: inset(1px);
  background: -webkit-linear-gradient(55deg, #E43424, #ffce00);
  -webkit-background-clip: text;
  background-size: 150% 200%;
  -webkit-text-fill-color: transparent;
  animation: GradientText 3s ease infinite;
}

.gradient-text {
}

@keyframes GradientText {
  0% {
    background-position: 0% 50%;
  }
  
  50% {
    background-position: 100% 50%;
  }
  
  100% {
    background-position: 0% 50%;
  }
}

