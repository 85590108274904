.container {
  padding-top: var(--navHeight);
  overflow-x: hidden;
  width: 100vw;
}

.videoHero {
  min-height: 85vh;
  position: relative;
  width: 100%;
}

.video {
  position: absolute;
  height: 100%;
  object-fit: cover;
  width: 100%;
  top: 0;
  z-index: 1;
}

.videoHeroContent {
  position: relative;
  min-height: 85vh;
  width: 100vw;
  padding: 2rem 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  z-index: 2;
}

.videoHeroContent img {
  height: 30vh;
}

.videoHeroContent p {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--grayEleven) !important;
  width: 65vw;
  text-align: center;
}

.titleBanner {
  display: flex;
  align-items: center;
  background-color: black;
  color: var(--grayEleven) !important;
  width: 100%;
  padding: 2rem;
  gap: 2rem;
}

.titleBannerContent {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.titleBannerContent h1 {
  font-size: 3rem;
  font-weight: 800;
  color: var(--grayEleven) !important;
}

.titleBannerForm {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  text-align: left;
}

.titleBannerForm h4 {
  font-size: 2rem;
  font-weight: 800;
  color: var(--grayEleven) !important;
}

.titleBannerForm p {
  font-size: 1rem;
  font-weight: 500;
  color: var(--grayEleven) !important;
}

.bannerForm {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
}

.bannerForm * {
  border: 1px solid var(--mainColor) !important;
}

.bannerForm input {
  width: calc((100% - .5rem) / 2);
  padding: .25rem .5rem;
  font-size: 1rem;
  font-weight: 500;
  color: rgba(255,255,255,0.7);
  background: none;
}

.bannerForm input::placeholder {
  color: rgba(255,0,0,0.6);
  font-weight: 600;
}

.buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
}

.bannerFormButton {
  padding: .5rem .5rem;
  font-size: 1rem;
  font-weight: 500;
  color: var(--grayEleven) !important;
  background: var(--grayOne) !important;
  border: 1px solid var(--grayEleven) !important;
  transition: all 0.3s ease-in-out;
}

.bannerFormButton:hover {
  background: rgba(255,0,0,0.6) !important;
  color: white;
  cursor: pointer;
  transform: scale(1.05);
}

.highlight {
  color: #ff0000;
  /*
   * color: var(--mainColor) !important;
   * background-image: linear-gradient(to bottom, #dd0000 25%, #220000);
   * -webkit-background-clip: text;
   * -webkit-text-fill-color: transparent;
   * -webkit-text-stroke: 1px transparent;
   */
}

.twoColumnStyling {
  background: var(--grayOne) !important;
  padding: 2rem 4rem;
  gap: 2rem;
}


@media (max-width: 800px) {
  .videoHeroContent img {
    width: 100%;
    height: auto;
  }
  .videoHeroContent p {
    font-size: 1.5rem;
    width: 80vw;
  }
  .titleBanner {
    flex-direction: column;
    padding: 4rem;
  }
  .titleBannerContent {
    width: 100%;
    gap: 2rem;
  }
  .titleBannerForm {
    width: 100%;
    gap: 2rem;
  }
  .bannerForm {
    width: 100%;
    gap: 1rem;
  }
  .bannerForm input {
    width: 100%;
  }
  .bannerFormButton {
    width: 100%;
  }
}
