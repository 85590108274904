.carousel-container {
  height: 80vh;
  background-color: #181818;
  display: flex;
  position: relative;
}

.carousel {
  display: flex;
  height: 100%;
  width: 100%;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
}

.carousel-item {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 0 100%;
  scroll-snap-align: start;
}

.carousel-item img {
  height: calc(100% - 5.5rem);
  width: 100%;
  object-fit: cover;
}

.carousel-item p {
  transition: all 1s ease-in-out;
}

.carousel-item .holder {
  position: absolute;
  transition: opacity 1s ease-in-out;
  bottom: 0;
  inset: auto 0 0 0;
  text-align: center;
  color: white;
  font-size: 4rem;
  padding-bottom: 8.5rem;
}

.holder p {
  transition: all 1s ease-in-out;
}

.carousel-btn {
  height: 30px;
  width: 30px;
  position: absolute;
  transform: rotate(45deg);
  z-index: 2;
  top: 50%;
  cursor: pointer;
  display: none;
}

.holder {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
}

.small-title {
  font-size: 4rem;
  font-weight: 500;
  color: #fff;
  text-align: center;
  line-height: 4rem;
}

.big-title {
  font-size: 6rem;
  font-weight: 500;
  color: #fff;
  text-align: center;
  line-height: 6rem;
}

.description {
  z-index: 100;
  font-size: 3rem;
  font-weight: 500;
  color: #fff;
  text-align: center;
  line-height: 1.5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background: black;
}

@media (width > 600px) {
  .carousel-btn {
    display: inline-block;
  }

  .carousel-item.active p {
    opacity: 1;
  }
  .carousel-item p {
    opacity: 0;
  }
  .carousel-item.active > .holder p {
    transform: scale(1.5);
    opacity: 1;
  }
  .carousel-item > .holder p {
    transform: scale(.7);
    opacity: 0;
  }
}

.left-btn {
  border-bottom: 4px solid rgb(256, 256, 256);
  border-left: 4px solid rgb(256, 256, 256);
  left: 20px;
}

.right-btn {
  border-right: 4px solid rgb(256, 256, 256);
  border-top: 4px solid rgb(256, 256, 256);
  right: 20px;
}

::-webkit-scrollbar {
  height: 0;
  width: 0;
}


.slide-capction {
  width: 100vw;
  height: 10vh;
  background: rgb(40,40,40);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  top: 10vh;
}

.slide-caption > p {
  color: #fffffa;
  background: rgb(40,40,40);
  font-size: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
}

.carousel-item-content {
  display: flex;
  height: 100%;
  flex-direction: column;
}

@media screen and (max-width: 940px) {
  .carousel-item .holder {
    padding-bottom: 10rem;
  }
  .description {
    z-index: 100;
    font-size: 2rem;
    font-weight: 500;
    color: #fff;
    text-align: center;
    line-height: 2.25rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background: black;
  }
}
