.wrapper {
  width: 100vw;
  min-height: 75vh;
  position: relative;
  background: black;
}

.container {
  width: 100%;
  min-height: 75vh;
  display: flex;
  padding: 2rem 0rem;
  padding-top: 0rem;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 3rem 2rem;
}

.title {
  width: calc(60% - 1rem);
  background: black;
  display: flex;
  align-items: center;
  padding: 4rem 5rem;
  padding-right: 5rem;
  color: white;
  text-align: end;
  flex-direction: column;
}

.hoverselectors {
  padding-top: 2rem;
  width: 100%;
  display: flex;
  gap: 0rem 1rem;
}

.hoveritem {
  width: 25%;
  text-align: center;
  font-size: 2vw;
  font-weight: bold;
  border: 2px solid red;
  opacity: .7;
  transition: all .2s ease;
}

.hoveritem:hover {
  opacity: 1;
  transform: scale(1.05);
}

.form {
  width: calc(40% - 1rem);
  background: black;
  transition: all .2s ease;
}

.title > h1 {
  font-size: 5rem;
  line-height: 6rem;
  border-bottom: 10px solid red;
}

.formTitle {
  font-size: 3rem;
  line-height: 3.5rem;
  font-weight: 700;
  text-align: center;
  color: white;
  padding: 2rem;
  padding-top: 0rem;
  transition: all .3s ease;
}

.formTitle > p {
  padding-bottom: 1rem;
  border-bottom: solid 2px red;
}

.formContent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 2rem 2.5rem;
  padding-top: 0rem;
  color: white;
}

.formContent > p {
  font-size: 1.25rem;
  text-align: center;
}

.formButton {
  text-decoration: none;
  font-size: 2rem;
  padding: 1rem 2rem;
  background: black;
  border: none;
  border-bottom: 2px solid red;
}

.content {
}



@media (max-width: 940px) {
  .title {
    width: 100%;
    padding: 2rem 2rem;
    padding-right: 4rem;
  }
  .title > h1 {
    text-align: left;
    font-size: 4rem;
    line-height: 4.5rem;
  }
  .form {
    width: 100%;
  }
  .container {
    gap: 3rem;
    flex-flow: column-reverse;
  }
  .hoverselectors {
    width: 100%;
    padding-top: 1.5rem;
    flex-wrap: wrap;
    gap: .5rem;
  }
  .hoveritem {
    width: calc(50% - .25rem);
    font-size: 1.5rem;
  }
}
