.Example {
  position: relative;
}

.react-pdf__Page__textContent, .react-pdf__Page__annotations{
  display: none;
}

.Example input,
.Example button {
  font: inherit;
}


.Example header {
  background-color: #323639;
  box-shadow: 0 0 .5rem rgba(0, 0, 0, 0.5);
  padding: 1.25rem;
  color: white;
}

.Example header h1 {
  font-size: inherit;
  margin: 0;
}

.Example__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: .75rem;
  background-color: var(--grayOne);
  position: relative;
}

.Example__container__load {
  color: white;
  background-color: var(--grayOne);
}

.Example__container__document {
  background-color: var(--grayOne);
  padding: 2rem 0rem;
}

.Example__container__document .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Example__container__document .react-pdf__Page {
  max-width: calc(100% - 2em);
  box-shadow: 0 0 .5rem rgba(0, 0, 0, 0.5);
  margin: 1em;
  border-radius: 1rem;
}

.Example__container__document .react-pdf__Page canvas {
  max-width: 50vw;
  height: auto !important;
  border-radius: 1rem;
}

.Example__container__document .react-pdf__message {
  padding: 1.5rem;
  color: white;
}

.pdfButtons {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 2rem;
}

.pdfButton {
  border: solid 1px red;
  color: white;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: .75rem;
  padding-bottom: .75rem;
  border-radius: 5px;
}
