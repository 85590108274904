
.floatingButtons {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: .75rem;
  justify-content: end;
  align-items: end;
  z-index: 10000;
  padding-right: 2rem;
  padding-bottom: 2rem;
}

.whatsapp, .email{
  width: 70px;
  height: 70px;
  transition: all .5s ease;
  border: 2px solid red;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,.75);
}

.whatsapp:hover, .email:hover {
  width: 85px;
  height: 85px;
}

.whatsappimg, .emailimg {
  width: 70%;
  height: 70%;
  text-shadow: 10px 10px #000;
}
