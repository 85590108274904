.container {
  width: 100vw;
  height: 50vh;
  display:flex;
}


.image {
  width: 50vw;
  object-fit: cover;
}


