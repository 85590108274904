.promotionbar {
  width: 100vw;
  min-height: 50px;
  /* background-color: #f23f0d; */
  color: #ff0000;
  background-color: #FFFFFA;
  display: flex;
  justify-content: center;
  align-items: center;
}

.promotionbarp {
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 1px;
}

.contactbar {
  width: 100vw;
  height: 42px;
  background-color: rgb(0,0,0);
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 3rem;
  padding: .5rem .8rem;
}

.socials {
  display: none;
  height: 100%;
  gap: 1rem;
  align-items: center;
}

.socialsImg {
  height: 80%;
  width: auto;
}

.mailphone {
  display: flex;
  height: 100%;
  gap: 1.5rem;
  align-items: center;
  margin: 0 1rem;
}

.mpcontainer {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  height: 80%;
}

.mpImg {
   height: 100%;
   width: auto;
}

.mpp {
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 1px;
  color: white;
  width: 100%;
}

@media screen and (max-width: 940px) {
  .promotionbar > p {
    font-size: 1rem;
    text-align: center;
    max-width: 90%;
  }
  .promotionbar {
    min-height: 40px;
  }
}
