.wrapper {
  width: 100vw;
  overflow-x: hidden;
  min-height: 35vh;
  background: black;
}

.container {
  min-height: 35vh;
  width: 100%;
  padding: 2rem;
  display: flex;
  gap: 1.5rem;
}

.pagemap {
  width: 28%;
  display: flex;
  flex-direction: column;
  padding: 0rem 1rem;
}

.contact {
  width: 28%;
  display: flex;
  flex-direction: column;
  padding: 0rem 1rem;
}

.logosec {
  width: 44%;
  padding: 0rem 1rem;
}

.sectitle {
  width: 100%;
  font-size: 1.75rem;
  color: white;
  font-weight: 600;
  border-bottom: 2px solid #E43424;
}

.pagemapcontent {
  background: grey;
}

.contactBanner {
  display: flex;
  flex-direction: column;
  gap: .75rem;
  width: 100%;
  align-items: center;
}

.contactBanner > img {
  width: 350px;
}

.contactBanner > p {
  font-size: 1.25rem;
  font-weight: 500;
  color: white;
  text-align: center;
}

.contactBanner > h6 {
  font-size: 1rem;
  color: white;
  font-weight: 500;
  text-align: center;
}
