.container {
  width: 100vw;
  padding: 4rem 8rem;
}

.contactBanner {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.contactForm {
  disply: flex;
  flex-direction: column;
  justify-content: center;
  width: 600px;
  gap: 2rem;
  background: green;
}

.socials{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  background: blue;
  width: 600px;
}
